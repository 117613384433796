import { Injectable } from '@angular/core';
import { ILoginModel, IChangePassModel, IUserTypeModel } from '../iModels/login.model';
import { ISocialMediaMasterModel, IQuoteModel, IBannerModel } from '../iModels/iMasterModels/socialMediaMaster.model';
import { IDepartmentModel } from '../iModels/iMasterModels/department.model';
import { IDepartmentQueModel } from '../iModels/iMasterModels/departmentQue.model';
import { IApiBaseObject } from '../iModels/api-base-object.model';
import { IVerifyKeyModel } from './../iModels/verify.model';
import { IVerifyEmailModel } from './../iModels/verifyEmail.model';
import { ICustomerTypeModel } from '../iModels/iMasterModels/customerType.model';
import { IPropertyTypeModel } from '../iModels/iMasterModels/propertyType.model';
import { IReviewForModel } from '../iModels/iMasterModels/reviewFor.model';
import { IPropertyMasterModel } from '../iModels/iMasterModels/propertyMaster.model';
import { IElementMasterModel } from '../iModels/iMasterModels/elementMaster.model';
import { IRangeTypeModel } from '../iModels/iMasterModels/rangeType.model';
import { IRangeModel } from '../iModels/iMasterModels/range.model';
import { IPropertyRangeModel } from '../iModels/iMasterModels/propertyRange.model';

import { ICustomersModel } from '../iModels/iCRMModels/customers.model';
import { IBonusPointModel } from '../iModels/iCRMModels/bonusPoint.model';
import { ICampaignModel } from '../iModels/iCRMModels/campaign.model';

import { IQsrReviewModel, IObjQuestionsModel } from '../iModels/iQsrReviewModels/qsrReview.model';

import { ILoyaltyProgramModel } from '../iModels/iSettingModels/loyaltyProgram.model';
import { ILoyaltyOfferModel } from '../iModels/iSettingModels/loyaltyOffer.model';

import { IPropertyModel } from '../iModels/iPropertyManagementModels/property.model';
import { IPropertyDepartmentModel } from '../iModels/iPropertyManagementModels/propertyDepartment.model';
import { IPropertyDepartmentQueModel } from '../iModels/iPropertyManagementModels/propertyDepartmentQue.model';
import { IPropertyCustomerTypeModel } from '../iModels/iPropertyManagementModels/propertyCustomerType.model';
import { ISmsEmailApiModel, IApiParamModel } from '../iModels/iPropertyManagementModels/smsEmailApi.model';
import { IPropertyCustomerCategoryModel } from '../iModels/iPropertyManagementModels/propertyCustomerCategory.model';

import { IAutoPilotModel } from '../iModels/iPromotionModels/autoPilot.model';

@Injectable()
export class ModelBindingService {
  endpoint;

  /*
   * @method mappingDataReturn()
   * @desc method to encode Array Data data.
   */

  mappingDataReturn(functionName, apiresponseData): any {
    apiresponseData = apiresponseData.map(
      res => this[functionName](res)
    );
    return apiresponseData;
  }
  /*
  * @method loginModelEncoder()
  * @desc method to encode login   json data.
 */
  loginModelEncoder(loginRequestData: ILoginModel): any {
    return {
      password: loginRequestData.password,
      username: loginRequestData.username,
      propertyCode: loginRequestData.propertyCode,
    };
  }

  /*
  * @method categoryMasterEncoder()
  * @desc method to encode user json data.
  */
  categoryMasterEncoder(data: ISocialMediaMasterModel): any {
    return {
      categoryName: data.categoryName,
      isActive: data.isActive,
      isFestival_Event: data.isFestival_Event,
      id: data.id,
    };
  }

  /*
   * @method departmentEncoder()
   * @desc method to encode user json data.
  */
  departmentEncoder(data: IDepartmentModel): any {
    return {
      departmentName: data.departmentName,
      isActive: data.isActive,
      id: data.id,
    };
  }
  /*
   * @method departmentQueEncoder()
   * @desc method to encode user json data.
   */
  departmentQueEncoder(data: IDepartmentQueModel): any {
    return {
      departmentId: data.departmentId,
      questionDescription: data.questionDescription,
      isMainQueston: data.isMainQueston,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method customerTypeEncoder()
   * @desc method to encode user json data.
   */
  customerTypeEncoder(data: ICustomerTypeModel): any {
    return {
      noofDays: data.noofDays,
      customerTypeName: data.customerTypeName,
      billAmount: data.billAmount,
      noofVisits: data.noofVisits,
      ordering: data.ordering,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method propertyTypeEncoder()
   * @desc method to encode user json data.
   */
  propertyTypeEncoder(data: IPropertyTypeModel): any {
    return {
      propertyTypeName: data.propertyTypeName,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method reviewForEncoder()
   * @desc method to encode user json data.
   */
  reviewForEncoder(data: IReviewForModel): any {
    return {
      reviewForName: data.reviewForName,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method propertyMasterEncoder()
   * @desc method to encode user json data.
   */
  propertyMasterEncoder(data: IPropertyMasterModel): any {
    return {
      propertyName: data.propertyName,
      city: data.city,
      address: data.address,
      noOfProperty: data.noOfProperty,
      isActive: data.isActive,
      id: data.id,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      ExpireDate: data.expireDate,
      pincode: data.pincode
    };
  }

  elementMasterEncoder(data: IElementMasterModel): any {
    return {
      Title: data.Title,
      ImageName: data.ImageName,
      Image_Base64: data.Image_Base64
    };
  }
  /*
   * @method rangeTypeEncoder()
   * @desc method to encode user json data.
   */
  rangeTypeEncoder(data: IRangeTypeModel): any {
    return {
      rangeTypeName: data.rangeTypeName,
      isActive: data.isActive,
      id: data.id,
    };
  }
  /*
   * @method rangeTypeEncoder()
   * @desc method to encode user json data.
   */
  rangeEncoder(data: IRangeModel): any {
    return {
      rangeFrom: data.rangeFrom,
      rangeTo: data.rangeTo,
      ordering: data.ordering,
      rangeTypeMasterId: data.rangeTypeMasterId,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method propertyRangeEncoder()
   * @desc method to encode user json data.
   */
  propertyRangeEncoder(data: IPropertyRangeModel): any {
    return {
      rangeFrom: data.rangeFrom,
      rangeTo: data.rangeTo,
      ordering: data.ordering,
      propertyId: data.propertyId,
      rangeTypeMasterId: data.rangeTypeMasterId,
      isActive: data.isActive,
      id: data.id,
    };
  }

  /*
   * @method customerEncoder()
   * @desc method to encode user json data.
   */
  customerEncoder(data: ICustomersModel): any {
    return {
      customername: data.customerName,
      loginUserId: data.loginUserId,
      contactNo: data.contactNo,
      email: data.email,
      doa: data.doa,
      dob: data.dob,
      remarks: data.remarks,
      propertyId: data.propertyId,
      departmentId: data.departmentId,
      responseFrom: data.responseFrom,
      billAmount: data.billAmount,
      BillNo: data.BillNo,
      isForeignCustomer: data.isForeignCustomer,
      // isOthers: data.isOthers,
      IsBuyer: data.isOthers,
      RedimPoints: data.RedimPoints,
      sendSMS: data.sendSMS,
      customerOffer_Promo_Camp_AutoId: data.customerOffer_Promo_Camp_AutoId,
      redimby: data.redimby,
      property_RedimValueId: data.property_RedimValueId,
      address: data.address,
      gender: data.gender,
      customerCategoryId: data.customerCategoryId,
      customerCategory: data.customerCategory,
      id: data.id,
      createdOn: data.createdOn,
      isActive: data.isActive,
      city: data.city,
      FilterPropertyId: data.FilterPropertyId
    };
  }
  /*
   * @method propertyRangeEncoder()
   * @desc method to encode user json data.
   */
  bonusPointEncoder(data: IBonusPointModel): any {
    return {
      contactNo: data.contactNo,
      email: data.email,
      remarks: data.remarks,
      redimPoint: data.redimPoint,
      pointStatus: data.pointStatus,
      customerId: data.customerId,
      id: data.id,
    };
  }
  /*
   * @method quoteEncoder()
   * @desc method to encode user json data.
   */
  quoteEncoder(data: IQuoteModel): any {
    return {
      quatation: data.quatation,
      categoryId: data.categoryId,
      Type: data.Type,
      ApproveTemplate: data.ApproveTemplate,
      MobileNo: data.MobileNo,
      IsSMS: data.IsSMS,
      ISWhatsup: data.ISWhatsup
    };
  }

  /*
   * @method quoteEncoder()
   * @desc method to encode user json data.
   */
  bannerEncoder(data: IBannerModel): any {
    return {
      imageDetailsList: data.imageDetailsList,
      categoryId: data.categoryId
    };
  }
  /*
 * @method loyaltyProgramEncoder()
 * @desc method to encode user json data.
 */
  loyaltyProgramEncoder(data: ILoyaltyProgramModel): any {
    return {
      royaltyPointType: data.royaltyPointType,
      amount: data.amount,
      amountPoint: data.amountPoint,
      id: data.id,
    };
  }
  /*
   * @method loyaltyOfferEncoder()
   * @desc method to encode user json data.
   */
  loyaltyOfferEncoder(data: ILoyaltyOfferModel): any {
    return {
      noofPonts: data.noofPonts,
      pointValue: data.pointValue,
      validityDate: data.validityDate,
      id: data.id,
      isActive: data.isActive,
      propertyId: data.propertyId,
    };
  }
  /*
 * @method propertyEncoder()
 * @desc method to encode user json data.
 */
  propertyEncoder(data: IPropertyModel): any {
    return {
      imageName: data.imageName,
      propertyName: data.propertyName,
      contactPerson: data.contactPerson,
      email: data.email,
      id: data.id,
      propertyMasterId: data.propertyMasterId,
      contactNo: data.contactNo,
      contactNo1: data.contactNo1,
      propertyTypeId: data.propertyTypeId,
      reviewForId: data.reviewForId,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      address: data.address,
      logo_Base64String: data.logo_Base64String,
      ExpireDate: data.expireDate,
      pincode: data.pincode
    };
  }
  /*
 * @method propertyDepartmentEncoder()
 * @desc method to encode user json data.
 */
  propertyDepartmentEncoder(data: IPropertyDepartmentModel) {
    return {
      departmentId: data.departmentId,
      propertyId: data.propertyId,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
* @method propertyDepartmentQueEncoder()
* @desc method to encode user json data.
*/
  propertyDepartmentQueEncoder(data: IPropertyDepartmentQueModel) {
    return {
      departmentId: data.departmentId,
      propertyId: data.propertyId,
      questionDescription: data.questionDescription,
      isMainQueston: data.isMainQueston,
      id: data.id,
      createdOn: data.createdOn,
      totalRows: data.totalRows,
      isActive: data.isActive,
    };
  }
  /*
 * @method propertyCustomerTypeEncoder()
 * @desc method to encode user json data.
 */
  propertyCustomerTypeEncoder(data: IPropertyCustomerTypeModel): any {
    return {
      propertyId: data.propertyId,
      customerTypeId: data.customerTypeId,
      propertyName: data.propertyName,
      noofDays: data.noofDays,
      customerTypeName: data.customerTypeName,
      billAmount: data.billAmount,
      noofVisits: data.noofVisits,
      ordering: data.ordering,
      isActive: data.isActive,
      id: data.id,
    };
  }
  /*
* @method smsEmailApiEncoder()
* @desc method to encode user json data.
*/
  smsEmailApiEncoder(data: ISmsEmailApiModel): any {
    return {
      propertyId: data.propertyId,
      apiFor: data.apiFor,
      apiDetails: data.apiDetails,
      isActive: data.isActive,
      id: data.id,
    };
  }
  /*
* @method apiParamEncoder()
* @desc method to encode user json data.
*/
  apiParamEncoder(data: IApiParamModel): any {
    return {
      propertyId: data.propertyId,
      propertyAPIId: data.propertyAPIId,
      parameter: data.parameter,
      parameterValue: data.parameterValue,
      isMobile: data.isMobile,
      isMessage: data.isMessage,
      isActive: data.isActive,
      id: data.id,
    };
  }
  /*
* @method propertyCustomerCategoryEncoder()
* @desc method to encode user json data.
*/
  propertyCustomerCategoryEncoder(data: IPropertyCustomerCategoryModel) {
    return {
      category: data.category,
      id: data.id,
    };
  }
  /*
* @method autoPilotEncoder()
* @desc method to encode user json data.
*/
  autoPilotEncoder(data: IAutoPilotModel) {
    return {
      autoPilot_PropertyId: data.autoPilot_PropertyId,
      wishMessage: data.wishMessage,
      rewardTitle: data.rewardTitle,
      whentoSendRewards: data.whentoSendRewards,
      campaignMessage: data.campaignMessage,
      rewardsValidity: data.rewardsValidity,
      isSMS: data.isSMS,
      isEmail: data.isEmail,
      isNotification: data.isNotification,
      uploadBannerImage: data.uploadBannerImage,
      image_Base64: data.image_Base64,
      id: data.id,
      IsOfferMessage: data.IsOfferMessage
    };
  }
  /*
* @method campaignEncoder()
* @desc method to encode user json data.
*/
  campaignEncoder(data: ICampaignModel) {
    return {
      title: data.title,
      message: data.message,
      validityInDays: data.validityInDays,
      isPromotion: data.isPromotion,
      isSMS: data.isSMS,
      isEmail: data.isEmail,
      IsWhatsup: data.IsWhatsup,
      isNotification: data.isNotification,
      uploadBannerImage: data.uploadBannerImage,
      image_Base64: data.image_Base64,
      contactIds: data.contactIds,
      FilterPropertyId: data.FilterPropertyId,
      EmailBody: data.EmailBody,
      EmailTemplateId: data.EmailTemplateId

    };
  }

   /*
* @method campaignEncoder()
* @desc method to encode user json data.
*/
referralEncoder(data: ICampaignModel) {
  return {
    title: data.title,
    message: data.message,
    validityInDays: data.validityInDays,
    isSMS: data.isSMS,
    isEmail: data.isEmail,
    isNotification: data.isNotification,
    uploadBannerImage: data.uploadBannerImage,
    image_Base64: data.image_Base64,
    contactIds: data.contactIds,
    FilterPropertyId: data.FilterPropertyId,
  };
}

  /*
* @method qsrReviewEncoder()
* @desc method to encode user json data.
*/
  qsrReviewEncoder(data: IQsrReviewModel) {
    const requestData = {
      remarks: data.remarks,
      gender: data.gender,
      customerName: data.customerName,
      mobile: data.mobile,
      email: data.email,
      doa: data.doa,
      dob: data.dob,
      type: data.type,
      objQuestions: this.mappingDataReturn('objQuestionsEncoder', data.objQuestions),
    };
    if (data.Ut) {
      requestData['Ut'] = data.Ut;
    } else if (data.UserToken) {
      requestData['UserToken'] = data.UserToken;
    }
    return requestData;
  }

   /*
* @method customerInfoEncoder()
* @desc method to encode user json data.
*/
customerInfoEncoder(data: ICustomersModel) {
  return {
    Id: data.id,
    CustomerName: data.customerName,
    Email: data.email,
    DOB: data.dob,
    DOA: data.doa,
    Address: data.address,
    Gender: data.gender,
    City: data.city
  };
}

  profilePasswordModelEncoder(data: IChangePassModel): any {
    return {
      newPassword: data.newPassword,
      oldPassword: data.oldPassword
    };
  }
  /*
* @method objQuestionsEncoder()
* @desc method to encode user json data.
*/
  objQuestionsEncoder(data: IObjQuestionsModel) {
    return {
      feed: data.feed,
      questionDescription: data.questionDescription,
      questionId: data.questionId,
    };
  }
  /*
* @method userTypeEncoder()
* @desc method to encode user json data.
*/
  userTypeEncoder(data: IUserTypeModel): any {
    return {
      propertyDepartmentId: data.propertyDepartmentId,
      propertyId: data.propertyId,
      email: data.email,
      mobileNo: data.mobileNo,
      userTypeId: data.userTypeId,
      loginId: data.loginId,
      password: data.password,
      name: data.name,
      id: data.id,
      disableBillAmount: data.disableBillAmount,
      disableBillNo: data.disableBillNo
    };
  }
  /**
   * @method setApiCallObject()
   * @desc method to set api base object data.
   * @param endpoint api endpoint url.
   * @param apiData api data to post.
   * @param tokenRequiredFlag api token required for the api call.
   * @param alertHide alert hide funcationality.
   */
  setApiCallObject(endpoint, apiData, tokenRequiredFlag, multipart): IApiBaseObject {
    return {
      endpoint: endpoint,
      apiData: apiData,
      tokenRequiredFlag: tokenRequiredFlag,
      multipart: multipart
    };
  }

  /**
   * @method replaceUrl()
   * @desc method to replace the url string .
   * @param url api endpoint url.
   * @param data api param data to post.
   * @return url string
   */

  replaceUrl(url, data): any {
    // Create regex using the keys of the replacement object.
    const regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g');
    if (data.page >= 1) {
      const demoData = Object.keys(data);
      const values = Object.values(data);
      this.endpoint = url + '?' + demoData[0] + '=' +
        values[0] + '&' + demoData[1] + '=' + values[1] +
        '&' + demoData[2] + '=' + values[2] +  '&' + demoData[3] + '=' + values[3];
    } else if (data.commonCode) {
      if (data.commonCode === 101) {
        this.endpoint = url + '?' + Object.keys(data)[0] + '=' +
          Object.values(data)[0];
      } else {
        this.endpoint = url + '?' + Object.keys(data)[0] + '=' +
          Object.values(data)[0] + '&' + Object.keys(data)[1] + '=' + Object.values(data)[1];
      }
    } else if (data.propertyId) {
      this.endpoint = url + '?' + Object.keys(data)[0] + '=' +
        Object.values(data)[0] + '&' + Object.keys(data)[1] + '=' + Object.values(data)[1] +
        '&' + Object.keys(data)[2] + '=' + Object.values(data)[2];
    } else if (data.categoryId) {
      this.endpoint = url + '?' + Object.keys(data)[0] + '=' +
        Object.values(data)[0] + '&' + Object.keys(data)[1] + '=' + Object.values(data)[1] +
        '&' + Object.keys(data)[2] + '=' + Object.values(data)[2];
    } else {
      // Replace the string by the value in object
      this.endpoint = url.replace(regex, (m, $1) => data[$1]);
    }
    // Search data attachment to the url
    // console.log(data.searchData);
    if (data.searchData && Object.keys(data.searchData).length > 0) {
      const demoData = Object.keys(data);
      const values = Object.values(data);
      this.endpoint = url + '?' + demoData[0] + '=' +
        values[0] + '&' + demoData[1] + '=' + values[1] +
        '&' + demoData[2] + '=' + values[2] +  '&' + demoData[3] + '=' + values[3];
      this.endpoint = this.endpoint + '&' + 'id=0' +
        '&' + this.createEndpointForSearching(data.searchData);
    }
    return this.endpoint;
  }

  /**
   * @method replaceUrlForDelete()
   * @desc method to replace the url string .
   * @param url api endpoint url.
   * @param data api param data to post.
   * @return url string
   */

  replaceUrlForDelete(url, data): any {
    const regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g');
    if (data.id >= 0) {
      this.endpoint = url + '?' + Object.keys(data)[0] + '=' +
        Object.values(data)[0];
    } else {
      // Replace the string by the value in object
      this.endpoint = url.replace(regex, (m, $1) => data[$1]);
    }
    return this.endpoint;
  }
  /**
   * @method createEndpointForSearching()
   * @desc method to create url if serching is there .
   * @param searchDataObject api param data to post.
   * @return url string
   */
  createEndpointForSearching(searchDataObject): any {
    //  console.log(searchDataObject);
    // const keys = Object.keys(searchDataObject)[0];
    // const value = Object.values(searchDataObject)[0];
    // const json = {
    //   [keys]: value
    // };

    const p = Object.keys(searchDataObject)
      .filter(function (key): any { return searchDataObject[key] !== ''; })
      .map(key => key + '=' + searchDataObject[key]).join('&');
    return p;
  }

}
