import { STORAGE_KEYS } from './../../../services/storage-keys-constants';
import { IPropertyCustomerCategoryService } from './../../../iservices/iPropertyMngServices/iPropertyCustomerCategory';
import { IReportsService } from './../../../iservices/iReportsServices/iReports';
import { Component, Inject, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDepartmentService } from '../../../iservices/iMasterServices/iDepartment';
import { IPropertyTypeService } from '../../../iservices/iMasterServices/iPropertyType';
import { IRangeService } from '../../../iservices/iMasterServices/iRange';
import { IRangeTypeService } from '../../../iservices/iMasterServices/iRangeType';
import { IPropertyMasterService } from '../../../iservices/iMasterServices/iPropertyMaster';
import { IReviewForService } from '../../../iservices/iMasterServices//iReviewFor';
import { IPropertyService } from '../../../iservices/iPropertyMngServices/iProperty';
import { IPropertyDepartmentService } from '../../../iservices/iPropertyMngServices//iPropertyDepartment';
import { IPromotionsService } from '../../../iservices/iPromotionServices/iPromotions';
import { IReviewsService } from '../../../iservices/iReviewsServices/iReviews';
import { IUserService } from '../../../iservices/iUser';
import { ICommonService } from '../../../iservices/iCommon';
import { CONSTANT } from '../../../utils/constant';
import { ICustomersService } from '../../../iservices/iCrmServices/iCustomers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  employeeRole: any;
  @Input()
  searchOptions: any;
  @Input()
  customSearchValues: any;
  @Input() activateApis: any;
  @Output() searchEvent = new EventEmitter<object>();
  searchData: any;
  departmentData;
  propertyTypeData;
  rangeTypeData;
  propertyNameData;
  commonCodes = CONSTANT.CommonCodes;
  allCities;
  allStates;
  allCountries;
  propertyMasterData;
  reviewForData;
  commonId = [];
  commonId1 = [];
  customerTypes = [];
  redeemList = [];
  propertyCustomerSearchData = [];
  visitsList = [];
  CategoryList = [];
  promotionStatusCombo;
  departmentCombo;
  userType;
  loginUserType: any;
  noOfProperty: any;
  userDepartmentData;
  propertyDepartmentData = [];
  propertyCustomerCategory = [];
  /** MultiSelect initialization */
  /** control for the MatSelect filter keyword multi-selection */
  itemMultiFilterCtrlForProperty: FormControl = new FormControl();
  NoofVisitId: FormControl = new FormControl();
  NoofRedimId: FormControl = new FormControl();
  CustomerCategoryId: FormControl = new FormControl();
  CustomerType: FormControl = new FormControl();
  itemMultiFilterCtrlForRangeType: FormControl = new FormControl();
  itemMultiFilterCtrlForDepartment: FormControl = new FormControl();
  itemMultiFilterCtrlForCountry: FormControl = new FormControl();
  itemMultiFilterCtrlForState: FormControl = new FormControl();
  itemMultiFilterCtrlForCity: FormControl = new FormControl();
  itemMultiFilterCtrlForPropertyDepartment: FormControl = new FormControl();
  itemMultiFilterCtrlForPropertyMaster: FormControl = new FormControl();
  itemMultiFilterCtrlForReviewFor: FormControl = new FormControl();
  itemMultiFilterCtrlForPromotionStatus: FormControl = new FormControl();
  itemMultiFilterCtrlForDepartmentCombo: FormControl = new FormControl();
  itemMultiFilterCtrlForUserType: FormControl = new FormControl();
  itemMultiFilterCtrlForUserDepartment: FormControl = new FormControl();

  /** list of items filtered by search keyword for multi-selection */
  filteredItemsMultiForProperty = new ReplaySubject();
  filteredItemsMultiForRangeType = new ReplaySubject();
  filteredItemsMultiForDepartment = new ReplaySubject();
  filteredItemsMultiForCountry = new ReplaySubject();
  filteredItemsMultiForState = new ReplaySubject();
  filteredItemsMultiForCity = new ReplaySubject();
  filteredItemsMultiForPropertyDepartment = new ReplaySubject();
  filteredItemsMultiForPropertyMaster = new ReplaySubject();
  filteredItemsMultiForReviewFor = new ReplaySubject();
  filteredItemsMultiForPromotionStatus = new ReplaySubject();
  filteredItemsMultiForDepartmentCombo = new ReplaySubject();
  filteredItemsMultiForUserType = new ReplaySubject();
  filteredItemsMultiForUserDepartment = new ReplaySubject();
  private _onDestroy = new Subject<void>();
  param = {
    page: 1,
    searchData: {},
    size: 0
  };
  sourceTypeSelectData: any;
  propertyId: any;
  isMainProperty: any;
  constructor(@Inject('IDepartmentService') private departmentService: IDepartmentService,
    @Inject('IUserService') private userService: IUserService,
    @Inject('IPropertyTypeService') private propertyTypeService: IPropertyTypeService,
    @Inject('IRangeService') private rangeService: IRangeService,
    @Inject('IRangeTypeService') private rangeTypeService: IRangeTypeService,
    @Inject('IPropertyService') private propertyService: IPropertyService,
    @Inject('IReviewForService') private reviewForService: IReviewForService,
    @Inject('IPropertyMasterService') private propertyMasterService: IPropertyMasterService,
    @Inject('IPropertyDepartmentService') private propertyDepartmentService: IPropertyDepartmentService,
    @Inject('IPromotionsService') private promotionsService: IPromotionsService,
    @Inject('ICommonService') private commonService: ICommonService,
    @Inject('ICustomersService') public customerService: ICustomersService,
    @Inject('IReviewsService') private reviewsService: IReviewsService,
    @Inject('IReportsService') private reportService: IReportsService,
    @Inject('IPropertyCustomerCategoryService') private propertyCustomerCategoryService: IPropertyCustomerCategoryService,
    private sharedService: SharedService
  ) {
    this.propertyId = this.sharedService.getDataFromStorage('propertyId');
    this.isMainProperty = this.sharedService.getDataFromStorage('isMainProperty');
    this.loginUserType = this.sharedService.getDataFromStorage(STORAGE_KEYS.ROLE);
    this.noOfProperty = this.sharedService.getDataFromStorage('noOfProperty');
   }

  ngOnInit(): void {
    this.searchData = [];
    this.searchOptions.forEach((element,i) => {
      this.searchData[i++] = '';
    });
    if (this.searchOptions[1] && this.searchOptions[1].details &&
      this.searchOptions[1].details['preSelected']) {
      this.searchData[1] = this.searchOptions[1].details['preSelected'];
    }
    if (this.activateApis) {
      if (this.activateApis.departmentComboSelect === 'departmentComboSelect') {
        this.getDepartmentCombo();
        this.itemMultiFilterCtrlForDepartmentCombo.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForDepartmentCombo();
          });
      }
    }
    if (this.activateApis) {
      if (this.activateApis.userTypeComboSelect === 'userTypeComboSelect') {
        this.getUserTypeCombo();
        this.itemMultiFilterCtrlForUserType.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForUserType();
          });
      }
    }

    if (this.activateApis) {
      if (this.activateApis.departmentSelect === 'departmentSelect') {
        this.getDepartment();
        this.itemMultiFilterCtrlForDepartment.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForDepartment();
          });
      }
    }
    if (this.activateApis) {
      if (this.activateApis.promotionStatusSelect === 'promotionStatusSelect') {
        this.getStatusCombo();
        this.itemMultiFilterCtrlForPromotionStatus.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForPromotionStatus();
          });
      }
    }

    if (this.activateApis === 'propertyType') {
      this.getPropertyType();
    }

    this.getCustomerData();
    // this.getPropertySearchData();
    if (this.activateApis === 'propertyCustCategory') {
      this.getCustomerCategory();
    }
    if (this.activateApis === 'userwise') {
      this.getSourceSelectData();
    }
    if (this.activateApis) {
      if (this.activateApis.rangeTypeSelect === 'rangeTypeSelect') {
        this.getRangeType();
        this.itemMultiFilterCtrlForRangeType.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForRangeType();
          });
      }
    }
    if (this.activateApis) {
      console.log('propertyTypeSelect',this.activateApis.propertyTypeSelect)
      if (this.activateApis.propertyTypeSelect === 'propertyTypeSelect') {
        this.getPropertyName();
        this.itemMultiFilterCtrlForProperty.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForProperty();
          });
      }
    }

    if (this.activateApis) {
      if (this.activateApis.countrySelect === 'countrySelect') {
        this.getCountries();
        this.itemMultiFilterCtrlForCountry.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForCountry();
          });
      }
    }
    if (this.activateApis) {
      if (this.activateApis.propertyMasterSelect === 'propertyMasterSelect') {
        this.getMainProperty();
        this.itemMultiFilterCtrlForPropertyMaster.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForPropertyMaster();
          });
      }
    }
    if (this.activateApis) {
      if (this.activateApis.reviewForSelect === 'reviewForSelect') {
        this.getReviewFor();
        this.itemMultiFilterCtrlForReviewFor.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForReviewFor();
          });
      }
    }
    if (this.activateApis) {
      if (this.activateApis.propertyDepartmentSelect === 'propertyDepartmentSelect') {
        this.itemMultiFilterCtrlForPropertyDepartment.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForPropertyDepartment();
          });
      }
    }
    this.searchData = [];
    let i = 0;
    this.searchOptions.forEach((element,i) => {
      this.searchData[i++] = '';
    });
    if (this.searchOptions[1] && this.searchOptions[1].details &&
      this.searchOptions[1].details['preSelected']) {
      this.searchData[1] = this.searchOptions[1].details['preSelected'];
    }
    if (this.searchOptions[0] && this.searchOptions[0].details &&
      this.searchOptions[0].details['preSelected']) {
      this.searchData[0] = Number(this.searchOptions[0].details['preSelected']);
      this.getPropId(this.searchData[0]);
    }
  }

  /*
   * @method getPropertyDepartmentDetails()
   * @desc used to get property Departments.
   */
  getPropertyDepartmentDetails(id): void {
    this.propertyDepartmentService.getPropertyDepartmentComboDetailsByPropertyId(id).subscribe(
      resp => {
        if (resp.data) {
          this.propertyDepartmentData = resp.data;
          this.filteredItemsMultiForPropertyDepartment.next(this.propertyDepartmentData.slice());
        }
      });
  }

  getCustomerCategory() {
    this.propertyCustomerCategoryService.getPropertyCustomerCategoryDetails({}).subscribe(
      (resp) => {
        if (resp.data) {
          this.propertyCustomerCategory = resp.data;
        }
      });
  }

  getCustomerData(): void {
    this.customerService.getAllDashBoardData().subscribe(res => {
      if (res.code === 1000) {
          this.customerTypes = res.data.propCustomerType;
          this.visitsList = res.data.noofVisits;
          this.redeemList = res.data.noofRedim;
          this.CategoryList = res.data.category;
      }
    }, error => {
      console.log(error);
    });
  }

  getPropertySearchData() {
    this.customerService.getPropertyForCustomerSearch(this.propertyId).subscribe((res: any) => {
      if (res.code === 1000) {
        this.propertyCustomerSearchData = res.data;
      }
    }, error => {
      console.log(error);
    });
  }

  /**
   * @description method to get search details and give as output.
   */
  searchDetail(): void {
    const searchInput = {};
    let i = 0;
    this.searchOptions.forEach((element,i) => {
      searchInput[element.key] = (this.searchData[i]) ? this.searchData[i] : '';
      i++;
    });
    this.searchEvent.next(searchInput);
  }

  /**
   * @description method to reset search details and give as output.
   */
  resetDetail(): void {
    const searchInput = {};
    this.searchData = [];
    let i = 0;
    this.searchOptions.forEach((element,i) => {
      searchInput[element.key] = '';
      this.searchData[i++] = '';
      this.searchEvent.next(searchInput);
    });
    this.searchDetail();
  }

  trackByFn(): any {
    return null;
  }

  /*
 * @method getDepartment()
 * @desc used to get Departments.
 */
  getDepartment(): void {
    this.departmentService.getDepartmentDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.departmentData = resp.data;
          this.filteredItemsMultiForDepartment.next(this.departmentData.slice());
        }
      });
  }

  /*
   * @method getPropertyType()
   * @desc used to get property type.
   */
  getPropertyType(): void {
    this.propertyTypeService.getPropertyTypeDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.propertyTypeData = resp.data;
        }
      });
  }

  getSourceSelectData(): void {
    this.reportService.getUserWiseCountReport({}).subscribe(
      resp => {
        if (resp['data']) {
          this.sourceTypeSelectData = resp.data;
        }
      });
  }

  /*
   * @method getRangeType()
   * @desc used to get range type.
   */
  getRangeType(): void {
    this.rangeTypeService.getRangeTypeDetails(this.param).subscribe(
      resp => {
        if (resp.data) {
          this.rangeTypeData = resp.data;
          this.filteredItemsMultiForRangeType.next(this.rangeTypeData.slice());
        }
      });
  }
  /*
   * @method getPropertyName()
   * @desc used to get property name.
   */
  getPropertyName(): void {
    this.propertyService.getPropertyDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.propertyNameData = resp.data;
          this.filteredItemsMultiForProperty.next(this.propertyNameData.slice());
        }
      });
  }
  /**
   * @method getStates()
   * @desc to get All states.
   */
  getStates(): any {
    const json = {
      commonCode: this.commonCodes.commonCode2,
      commonId: this.commonId
    };
    this.commonService.getStates(json).
      subscribe(
        resp => {
          if (resp['data']) {
            resp = resp['data'];
            this.allStates = resp;
            this.filteredItemsMultiForState.next(this.allStates.slice());
          }
        });
  }

  /**
 * @method getCites()
 * @desc to get All cities.
 */
  getCites(): any {
    const json = {
      commonCode: this.commonCodes.commonCode3,
      commonId1: this.commonId1
    };
    this.commonService.getCites(json).
      subscribe(
        resp => {
          if (resp['data']) {
            resp = resp['data'];
            this.allCities = resp;
            this.filteredItemsMultiForCity.next(this.allCities.slice());
          }
        });
  }

  /**
   * @method getCountries()
   * @desc to get countries.
   */
  getCountries(): any {
    const json = {
      commonCode: this.commonCodes.commonCode
    };
    this.commonService.getCountries(json).
      subscribe(
        resp => {
          if (resp['data']) {
            resp = resp['data'];
            this.allCountries = resp;
            this.filteredItemsMultiForCountry.next(this.allCountries.slice());
          }
        });
  }

  /**
 * @method changeCountry()
 * @param e: getting value
 * @desc to get All commonid.
 */
  changeCountry(e): void {
    this.commonId = e;
    if (this.commonId) {
      this.getStates();
      this.itemMultiFilterCtrlForState.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterItemsMultiForState();
        });
    }
  }
  /**
   * @method changeState()
   * @param e: getting value
   * @desc to get All commonid1.
   */
  changeState(e): void {
    this.commonId1 = e;
    if (this.commonId1) {
      this.getCites();
      this.itemMultiFilterCtrlForCity.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterItemsMultiForCity();
        });
    }
  }
  /*
 * @method getMainProperty()
 * @desc used to get main property.
 */
  getMainProperty(): void {
    this.propertyMasterService.getPropertyMasterDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.propertyMasterData = resp.data;
          this.filteredItemsMultiForPropertyMaster.next(this.propertyMasterData.slice());
        }
      });
  }
  /*
  * @method getReviewFor()
  * @desc used to get review.
  */
  getReviewFor(): void {
    this.reviewForService.getReviewForDetails(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.reviewForData = resp.data;
          this.filteredItemsMultiForReviewFor.next(this.reviewForData.slice());
        }
      });
  }

  /*
 * @method getStatusCombo()
 * @desc used to get status combo.
 */
  getStatusCombo(): void {
    this.promotionsService.getStatusCombo().subscribe(
      resp => {
        if (resp) {
          this.promotionStatusCombo = resp;
          this.filteredItemsMultiForPromotionStatus.next(this.promotionStatusCombo.slice());
        }
      });
  }
  /*
* @method getDepartmentCombo()
* @desc used to get department combo.
*/
  getDepartmentCombo(): void {
    this.reviewsService.getDepartmentCombo(this.param).subscribe(
      resp => {
        if (resp['data']) {
          this.departmentCombo = resp['data'];
          this.filteredItemsMultiForDepartmentCombo.next(this.departmentCombo.slice());
        }
      });
  }
  /*
 * @method getUserTypeCombo()
 * @desc used to get user type.
 */
  getUserTypeCombo(): void {
    this.userService.getUserTypeCombo(this.param).subscribe(
      resp => {
        if (resp) {
          this.userType = resp.data;
          this.filteredItemsMultiForUserType.next(this.userType.slice());
        }
      });
  }

  getPropId(id): void {
    const param = {
      page: 1,
      searchData: {
        propertyId: id
      },
      size: 0
    };
    if (this.activateApis) {
      if (this.activateApis.userDepartmentComboSelect === 'userDepartmentComboSelect') {
        this.userService.getUserDepartmentCombo(param).subscribe(
          resp => {
            if (resp) {
              this.userDepartmentData = resp.data;
              if (!this.userDepartmentData) {
                this.userDepartmentData = [];
                this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
              } else {
                this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
              }
            }
          });
        this.itemMultiFilterCtrlForUserDepartment.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterItemsMultiForUserDepartment();
          });
      }
    }
    if(id > 0) {
      this.getPropertyDepartmentDetails(id);
    }
  }

  // Search select implementation below:

  /**
   * @method filterItemsMultiForProperty()
   * @desc filter property list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForProperty(): any {
    if (!this.propertyNameData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForProperty.value;
    if (!search) {
      this.filteredItemsMultiForProperty.next(this.propertyNameData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForProperty.next(
      this.propertyNameData.filter(item =>
        item.propertyName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
 * @method filterItemsMultiForRangeType()
 * @desc filter range type list.
 * @returns list of items filtered by search keyword for multi-selection.
 */
  filterItemsMultiForRangeType(): any {
    if (!this.rangeTypeData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForRangeType.value;
    if (!search) {
      this.filteredItemsMultiForRangeType.next(this.rangeTypeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForRangeType.next(
      this.rangeTypeData.filter(item =>
        item.rangeTypeName.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * @method filterItemsMultiForDepartment()
   * @desc filter department list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForDepartment(): any {
    if (!this.departmentData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForDepartment.value;
    if (!search) {
      this.filteredItemsMultiForDepartment.next(this.departmentData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForDepartment.next(
      this.departmentData.filter(item =>
        item.departmentName.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * @method filterItemsMultiForCountry()
   * @desc filter country list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForCountry(): any {
    if (!this.allCountries) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForCountry.value;
    if (!search) {
      this.filteredItemsMultiForCountry.next(this.allCountries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForCountry.next(
      this.allCountries.filter(item =>
        item.commonDesc.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * @method filterItemsMultiForState()
   * @desc filter state list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForState(): any {
    if (!this.allStates) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForState.value;
    if (!search) {
      this.filteredItemsMultiForState.next(this.allStates.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForState.next(
      this.allStates.filter(item =>
        item.commonDesc1.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * @method filterItemsMultiForCity()
   * @desc filter city list.
   * @returns list of items filtered by search keyword for multi-selection.
   */
  filterItemsMultiForCity(): any {
    if (!this.allCities) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForCity.value;
    if (!search) {
      this.filteredItemsMultiForCity.next(this.allCities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForCity.next(
      this.allCities.filter(item =>
        item.commonDesc2.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForPropertyMaster()
* @desc filter Property master list.
* @returns list of items filtered by search keyword for multi-selection.
*/

  filterItemsMultiForPropertyMaster(): any {
    if (!this.propertyMasterData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForPropertyMaster.value;
    if (!search) {
      this.filteredItemsMultiForPropertyMaster.next(this.propertyMasterData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForPropertyMaster.next(
      this.propertyMasterData.filter(item =>
        item.propertyName.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
  * @method filterItemsMultiForReviewFor()
  * @desc filter review for list.
  * @returns list of items filtered by search keyword for multi-selection.
  */

  filterItemsMultiForReviewFor(): any {
    if (!this.reviewForData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForReviewFor.value;
    if (!search) {
      this.filteredItemsMultiForReviewFor.next(this.reviewForData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForReviewFor.next(
      this.reviewForData.filter(item =>
        item.reviewForName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForPropertyDepartment()
* @desc filter property department list.
* @returns list of items filtered by search keyword for multi-selection.
*/
  filterItemsMultiForPropertyDepartment(): any {
    if (!this.propertyDepartmentData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForPropertyDepartment.value;
    if (!search) {
      this.filteredItemsMultiForPropertyDepartment.next(this.propertyDepartmentData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForPropertyDepartment.next(
      this.propertyDepartmentData.filter(item =>
        item.departmentName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForPromotionStatus()
* @desc filter review for list.
* @returns list of items filtered by search keyword for multi-selection.
*/

  filterItemsMultiForPromotionStatus(): any {
    if (!this.promotionStatusCombo) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForPromotionStatus.value;
    if (!search) {
      this.filteredItemsMultiForPromotionStatus.next(this.promotionStatusCombo.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForPromotionStatus.next(
      this.promotionStatusCombo.filter(item =>
        item.statusName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForDepartmentCombo()
* @desc filter department for list.
* @returns list of items filtered by search keyword for multi-selection.
*/

  filterItemsMultiForDepartmentCombo(): any {
    if (!this.departmentCombo) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForDepartmentCombo.value;
    if (!search) {
      this.filteredItemsMultiForDepartmentCombo.next(this.departmentCombo.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForDepartmentCombo.next(
      this.departmentCombo.filter(item =>
        item.departmentName.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
* @method filterItemsMultiForUserType()
* @desc filter user list.
* @returns list of items filtered by search keyword for multi-selection.
*/
  filterItemsMultiForUserType(): any {
    if (!this.userType) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForUserType.value;
    if (!search) {
      this.filteredItemsMultiForUserType.next(this.userType.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForPropertyDepartment.next(
      this.userType.filter(item =>
        item.name.toLowerCase().indexOf(search) > -1)
    );
  }
  /**
 * @method filterItemsMultiForPropertyDepartment()
 * @desc filter property department list.
 * @returns list of items filtered by search keyword for multi-selection.
 */
  filterItemsMultiForUserDepartment(): any {
    if (!this.userDepartmentData) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrlForUserDepartment.value;
    if (!search) {
      this.filteredItemsMultiForUserDepartment.next(this.userDepartmentData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMultiForUserDepartment.next(
      this.userDepartmentData.filter(item =>
        item.departmentName.toLowerCase().indexOf(search) > -1)
    );
  }

}
